<template>
  <main v-if='!kql.loading' ref='targetElement'>
    <header>
      <h1 v-if='kql.page.title' v-html='kql.page.title'/><h1 v-if='kql.page.appendix' v-html='kql.page.appendix'/>
    </header>
    <Layout :content='kql.page.layout' :page='kql.page' fill='white'/>
  </main>
</template>

<script setup>
import Layout from '../components/Layout.vue'
import MainHeader from '../components/MainHeader.vue'
import { useStore } from '.././KQL.js'
import { onMounted, onUnmounted, watch, ref } from 'vue'
//import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { clearBodyLocks, lock, unlock } from 'tua-body-scroll-lock'
const props = defineProps(['origin']);

const kql = useStore();
const emit = defineEmits(['reasonsToShowTheVeil', 'changeColors', 'pageToGoBackTo'])
const targetElement = ref(null);
const body = ref(null);
const pageToGoBackTo = ref(false);

onMounted(() => {
  emit('reasonsToShowTheVeil', 1);
  document.querySelector('body').style.overflow = 'hidden';
  if (targetElement.value) {
    lock();
  }
});
onUnmounted(() => {
  emit('reasonsToShowTheVeil', -1);
  clearBodyLocks();
  document.querySelector('body').style.overflow = 'inherit';
});

watch(() => kql.page.startcolor, () => {
  emit('changeColors', kql.page.startcolor, kql.page.endcolor);
});

watch(() => kql.page.uid, () => {
  if(!pageToGoBackTo.value){
    pageToGoBackTo.value = kql.page.uid;
    emit('pageToGoBackTo', kql.page.uid);
  }
});

</script>

<style scoped>
@import '../css/breakpoints.css';

  main#Overlay {
    --border: rgba(255,255,255,0.5);
    position: fixed;
    padding-top: 17.5rem;
    padding-bottom: 12rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    padding-left: 12rem;
    padding-right: 12rem;
    z-index: 1;
    color: #f1f2f2;
  }

  main#Overlay hr {
    border-top-color: #f1f2f2;
    opacity: 0.666;
  }

  @media (--phone) {
    main#Overlay {
      padding-left: 4rem;
      padding-right: 4rem;
      padding-top: 17.5rem;
      padding-bottom: 12rem;
    }
  }

  summary {
    font-size: 2em;
  }

  img {
    width: 100%;
    vertical-align: bottom;
  }

  header {
    display: flex;
    justify-content: space-between;
  }

  h1 {
    font-size: 4rem;
  }

  main {
    overflow-scrolling: touch;
  }
</style>

<style>
  @media print {

    main.Home > footer, main.Home > aside{
      display: none !important;
    }

    main.Home > header {
      position: absolute;
    }

    hr {
      display: none !important;
    }

    main#Overlay {
      opacity: 1;
      overflow: visible !important;
      position: static !important;
      bottom: auto !important;
      height: auto !important;
      max-height: auto !important;
      z-index: 1000;
    }
  }
</style>
