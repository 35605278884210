<template>
  <figure class='gallery'>
    <header v-if='filenames.length > 1'>
      <nav>
        <i @click='current = index-1'
           :class='{active: current==index-1}'
           v-for='index in filenames.length'
           v-html='index'
           :key='index'/>
      </nav>
    </header>
    <section>
      <nav @click='nextOrPrev' class='back-and-forth' v-if='filenames.length > 1'>
        <arrow class='arrow' direction='right' />
        <arrow class='arrow' direction='left' />
      </nav>
      <div v-for='(image, index) in filenames' :key='index' :class='getClass(index)'>
        <img @click='nextOrPrev' v-if='fileByUuid(files, image)' :srcset='fileByUuid(files, image).srcset' />
        <h5 v-if='fileByUuid(files, image)' class='image caption' v-html="fileByUuid(files, image).caption" />
      </div>
      <h5 v-if='block.content.caption' class='gallery caption' v-html="block.content.caption" />
    </section>
  </figure>
</template>

<script setup>
  import { ref } from 'vue'
  import Arrow from './Arrow.vue';
  import { fileByUuid } from '.././helpers.js'
  const props = defineProps(['block', 'filenames', 'files']);
  const current = ref(0);
  const slideShow = function(event){
    next();
  }
  if(props.block.content.autoplay=='true'){
    const intervalID = setInterval(slideShow, props.block.content.time * 1000);
  }
  const nextOrPrev = function(event){
    let distance = (event.clientX-(window.innerWidth/2));
    if (distance >= 0) current.value = current.value + 1 < props.filenames.length ? current.value + 1 : 0;
    if (distance < 0) current.value = (current.value - 1 >= 0 ? current.value - 1 : props.filenames.length-1);
  }
  const next = function(event){
    current.value = current.value + 1 < props.filenames.length ? current.value + 1 : 0;
  }
  const getClass = function(index){
    if(index == current.value) return 'current';
    if(index == (current.value + 1 < props.filenames.length ? current.value + 1 : 0)) return 'next';
    if(index == (current.value - 1 >= 0 ? current.value - 1 : props.filenames.length-1)) return 'prev';
  }
</script>

<style scoped>
@import '../css/breakpoints.css';

figure.gallery {
  box-sizing: border-box;
  position: relative;
  margin: auto;
  margin-top: 2rem;
  overflow: visible;
  margin-bottom: 4rem;
}

section {
  width: 100%;
  padding-bottom: 66%;
  position: relative;
}

section div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

section div img {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 5rem;
  max-width: 100%;
  max-height: calc(100% - 6rem);
}

section div.current {
  opacity: 1;
  pointer-events: all;
}

section div:not(.current) {
  opacity: 0;
  pointer-events: none;
}

h5.caption {
  font-size: 0.95em;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 54em;
  margin: auto;
}

h5.gallery.caption {
  bottom: -2.5rem;
}

h5.image.caption {
  top: calc(100% - 2rem);
  text-align: center;
}

h5.image.caption:deep(p) {
  text-align: center;
}

h5.caption:deep(a) {
  text-decoration: underline;
}

@media (--phone) {
  h5.caption {
    font-size: 1.5em;
  }
}

nav.back-and-forth {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  height: 4rem;
  width: 100%;
}

header nav {
  font-size: 1.4rem;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

header nav i {
  font-style: normal;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  padding: 0.35em 0.45em 0.45em;
  line-height: 1.1em;
  width: 2.25em;
  border-radius: 50%;
  border: var(--thinline);
  border-color: var(--first);
  margin-right: 0.5em;
  height: 2.25em;
  user-select: none;
  opacity: 0.5;
  border: 1px solid transparent;
  text-align: center;
}

header nav i.active, header nav i:hover {
  opacity: 1;
}

header nav i.active {
  border: 1px solid var(--border);
}


svg.arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  opacity: 0;
}

svg.arrow:hover {
  cursor: pointer;
}

nav.back-and-forth:deep(svg.left) {
  left: -4.5em;
}


nav.back-and-forth:deep(svg.right) {
  right: -4.5em;
}

</style>

<style>
  figure.gallery figure.imagebox img.content.show {
    transform: scale(0.4);
  }
</style>
