<template>
  <main ref='body'>
    <router-view />
  </main>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { watch, ref } from 'vue'
import Veil from './components/Veil.vue'
import RouterViewWithTransitions from './components/ViewTransitions.vue'
import { useStore } from './KQL.js'
import { useTitle } from 'vue-page-title';

//Components
//const components = {Veil, Navigation, RouterViewWithTransitions}

//Get route so we know where we are.
const route = useRoute()

//Setup Kirby (Timeout just so we don't load it too quickly.)
const kql = useStore();
setTimeout(function() { kql.loadPageAndSite(route.path, route.name) });

//Bind Router and KQLStore
watch(route, (route) => kql.loadPage(route.path, route.name));

const { setTitle } = useTitle();
//Bind Title to Title
watch(kql, (page) => {
  setTitle(kql.page.title);
});
</script>
