<template>
  <hr :class='{"visible" : showveil}' />
</template>

<style scoped>
  hr {
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    background: v-bind(startcolor!= 0 ? 'linear-gradient(180deg,'+startcolor+' 0%, '+endcolor+' 100%)' : 'rgb(48,25,30)');
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* mix-blend-mode: multiply; */
    z-index: 1;
    cursor: pointer;
  }

  hr {
    transition: 0.5s opacity ease-in-out;
    pointer-events: none;
    opacity: 0;
  }

  hr.visible {
    pointer-events: all;
    opacity: 0.9;
  }
</style>

<script>
export default {
  props: ['showveil', 'startcolor', 'endcolor']
}
</script>
