<template>
  <article v-if='content'>
    <section v-for='(section, alpha) in JSON.parse(content)' :key='alpha' :class='typeOfFirstBlock(section)'>
      <div v-for='(column, beta) in section.columns' class='column' :key='beta'>
        <Blocks :content='column.blocks' :page='page' :fill='fill'/>
      </div>
    </section>
  </article>
</template>

<script setup>
  import Blocks from './Blocks.vue'
  const props = defineProps(['content', 'page', 'fill']);
  //This class is needed in the front page.
  const typeOfFirstBlock = function(section) {
    return section.columns[0].blocks[0] ? section.columns[0].blocks[0].type : false;
  }
</script>

<style scoped>
  @import '../css/breakpoints.css';

  article > section {
    width: var(--columnwidth);
    display: flex;
    flex-direction: var(--columndirection);
    gap: 4em;
    padding-top: 1em;
    padding-bottom: 1em;
    color: var(--rootcolor);
  }

  article section div:only-child {
    width: 100%;
  }

  article section div.column {
    flex-grow: 3;
    flex-basis: 50%;
  }


  main section.heading {
    margin-top: 6em;
  }

  main section.quote {
    margin-bottom: 3em;
  }

  article section div:not(:only-child) {
    width: var(--leftcolumnwidth);
  }

  article section div:not(:only-child) + div {
    width: var(--rightcolumnwidth);
    padding-right: 6em;
    box-sizing: border-box;
  }

  figure.image {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
    height: 30rem;
    position: relative;
  }

  figure.image img {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }

  hr {
    border: none;
    border-top: 1px solid var(--border);
    outline: none;
    margin: 0;
  }

  article section table {
    column-count: 2;
    display: block;
  }

  article section table tr {
    display: block;
    text-align: left;
  }

  article section table td {
    display: block;
    font-size: var(--regular);
  }

  article secton.code:deep(iframe) {
    outline: none;
    border: none;
  }
</style>

<style>
  article > section > div p {
    font-size: var(--regular);
    line-height: 1.5em;
    text-align: left;
    max-width: 54em;
  }

  article > section > table td {
    font-size: 1.1rem;
    line-height: 1.5em;
    text-align: left;
  }
</style>
