<template>
  <nav class="related" :class='size'>
    <router-link :to='id' v-for='id in paths(pages)'>
      <h3 class='caret' v-if="pageFromPath(id, kql.pages)" v-html="pageFromPath(id, kql.pages).title" />
      <h3 v-if="pageFromPath(id, kql.pages)" v-html="pageFromPath(id, kql.pages).appendix" />
    </router-link>
  </nav>
</template>

<script setup>
  import { useStore } from '../KQL.js'
  import { pageFromPath } from '../helpers.js'
  const kql = useStore();
  const props = defineProps(['pages', 'size']);
  const paths =  function(structure){
    structure = structure.map(row => row['page'][0]);
    return structure
  }
</script>

<style scoped>
@import '../css/breakpoints.css';

  h3 {
    padding-left: 0.75em;
    line-height: 1.1em;
    margin: 0;
    position: relative;
  }

  h3.caret::before {
    position: absolute;
    left: 0;
    content: '›';
    transition: transform 0.1s ease-in-out;
  }

  h3:not(.caret) {
    text-align: right;
  }

  @media (--phone) {
    h3 {
      line-height: 1.2em;
      font-size: 2.25rem;
    }
  }

  a:hover h3.caret::before {
    transform: translateX(0.1em);
  }


  nav.related {
    margin: 0;
    padding: 0;
  }

  　.Large {
    font-size: 1.6rem;
  }

  nav.Small {
    font-size: 1.15rem;
  }

  nav.Large:deep(h3) {
    letter-spacing: -0.018em;
  }

  nav.Small:deep(h3) {
    letter-spacing: -0.01em;
  }

  nav.related:deep(a) {
    line-height: 1.2em;
    box-sizing: border-box;
    border-top: 1px solid var(--border);
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
  }

  nav.related  {
    padding-bottom: 0.3em;
    border-bottom: 1px solid var(--border);
    margin-bottom: 3rem;
  }

</style>
