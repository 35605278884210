<template>
  <router-view v-slot="{ Component }" :name='name'>
    <keep-alive>
      <section>
        <Transition>
          <component :key='route.path' class='MainViewWrapper' :is="Component" />
        </Transition>
      </section>
    </keep-alive>
  </router-view>
</template>

<script setup>
  import { onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  const props = defineProps(['name']);
  const route = useRoute()
</script>

<style scoped>
section {
  position: relative;
}

.MainViewWrapper {
  width: 100%;
  z-index: 0;
  position: relative;
  will-change: opacity;
  transform: translate3d(0,0,0);
  --time: 500ms;
  --distance: 0.25em;
  --scale: scaleX(4);
  --smallscale: scaleX(0.00125);
  --bezier: cubic-bezier(0.81,0.01,0.21,1.01);
}
.MainViewWrapper.v-enter-active {
transition: all var(--time) var(--bezier) var(--time);
position: absolute;
top: 0;
}
.MainViewWrapper.v-leave-active {
transition: all var(--time) var(--bezier) 0ms;
position: absolute;
top: 0;
/* transform-origin: 0% 50%; */
}
.MainViewWrapper.v-enter-active {
opacity: 0.01;
/* transform: var(--scale); */
/* transform-origin: 100% 50%; */
}
.MainViewWrapper.v-enter-to {
opacity: 1;
/* transform: scale(1); */
}
.MainViewWrapper.v-leave {
opacity: 1;
}
.MainViewWrapper.v-leave-to {
opacity: 0.01;
}
</style>
