<script setup>
  import { vueVimeoPlayer as VimeoPlayer } from 'vue-vimeo-player'
  import {  ref } from 'vue'
  const emit = defineEmits(['metadata']);
  const props = defineProps(['url']);
  const vimeoOptions = {
    controls: false,
    responsive: true,
    dnt: true
  };
  const vimeoPlayer = ref(null);
  const metaData = ref(null);
  const play = () => vimeoPlayer.value.play();
  const pause = () => vimeoPlayer.value.pause();
  defineExpose({play, pause});
  //const onReady = () => {
  //  console.log(vimeoPlayer.value);
  //}
  const url = new URL(props.url);
  const endpoint ='/api/vimeo?url='+url
  import axios from 'axios';
  axios.get(endpoint).then(response => {
    emit('metadata', response.data);
  });
</script>

<template>
  <VimeoPlayer class='player' ref='vimeoPlayer' :video-url='props.url' :options='vimeoOptions' /><!--@ready="onReady"-->
</template>

<style scoped>
  .player {
    background-color: black;
    /* aspect-ratio: 16/9; */
  }
</style>
