<template>
  <div :class='block.content.alignment' class='aligned button'>
    <button @click='navigate()' :class='[ (block.content.fullwidth == "true") ? "fullwidth" : ""]'
  v-html='block.content.caption' />
  </div>
</template>

<style scoped>
  div {
    width: 100%;
  }

  div.left {
    text-align: left;
  }

  div.right {
    text-align: right;
  }

  div.center {
    text-align: center;
    padding-left: 2em;
  }

  button.fullwidth {
    min-width: 40rem;
    margin: auto;
  }

  button:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);
  }
</style>

<script setup>
  import { useStore } from '.././KQL.js'
  import { useRoute } from 'vue-router'
  import { useRouter } from 'vue-router'
  import { saveAs } from 'file-saver';

  const route = useRoute()
  const router = useRouter()
  const kql = useStore();
  const props = defineProps(['block']);
  const navigate = function(){
    if(props && props.block){
      let target, url;
      if(props.block.content.targetisdownload == 'true') {
        var baseurl = window.location.href;
        var file = props.block.content.downloadtarget[0]
        saveAs(baseurl+'/'+file);
        return true;
      }
      try {
        url = new URL(props.block.content.target);
      } catch (error) {
        url = { hostname: false, pathname : false };
      }
      let from = new URL(kql.site.url);
      let targetissubpage = (url.hostname === from.hostname)
      if(props.block.content.targetisdownload != 'true' && targetissubpage){
        router.push(url.pathname);
      }
      if(props.block.content.targetisdownload != 'true' && !targetissubpage){
        window.open(props.block.content.target, '_blank', 'noreferrer');
      }
    }
  };
</script>
