<template>
  <div v-if='!subscribed'>
    <input v-model="email" type="email" placeholder="Email Address">
    <button @click="subscribe" v-on:keyup.enter="subscribe">Subscribe</button>
  </div>
  <div v-if='subscribed'>
    <h4>Thank you for your subscription.</h4>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';

const email = ref('');
const subscribed = ref(false);

const subscribe = () => {
  const api = document.location.origin+'/api/subscribe';
  const query = {email: email.value};
  axios.post(api, query)
    .then(response => {
      if(response.data.status=='202') {
        subscribed.value = true;
        console.info('Subscribed successfully!');
      } else {
        console.error(response.data);
      }
    })
    .catch(error => {
      console.error(error);
      alert('Error subscribing to the newsletter');
    });
};
</script>

<style scoped>
  section {
    /* display: flex; */
  }

  input {
    font-family: "Maison Neue Demi";
    font-size: 1.25rem;
    padding-top: 0.75em;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    background-color: transparent !important;
  }

  input:-internal-autofill-selected {
    background-color: transparent !important;
  }

  input + button {
    /* margin-left: 3rem; */
  }

  button {
    margin-top: 1.5rem;
    margin-bottom: 4rem;
  }
</style>
