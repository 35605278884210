// stores/counter.js
import { defineStore } from 'pinia';
import { cleanUpJSON } from './helpers.js'
import axios from 'axios';
import For from './KQLSelects.js';
const select = For;

const ArrayOfPageUIDsFromPath = function(path = 'home') {
  path = path.replace(/\//, "");
  let pages = !path ? ['home'] : path.split('/');
  let language = 'en';
  if(pages[0] == document.documentElement.lang) language = pages.shift();
  return (pages.length == 0) ? ['home'] : pages;
}

const KQLQueryStringFromArrayOfParentIDs = function(pages = ['home']) {
  let queryString = 'page("'+pages.shift()+'")';
  while (pages.length) {
    queryString = queryString + '.children.find("'+pages.shift()+'")'
  }
  return queryString;
}

//Store
export const useStore = defineStore('kirby', {
  state: () => {
    return {
      loading: 0,
      site: {},
      pages: {},
      page: {},
      children: {},
      api: document.location.origin+"/api/query",
      //api: "/api/query",
    }
  },
  actions: {
    //Load Both Page & Site
    loadPageAndSite(path = 'home', template) {
      this.loading = this.loading+1;
      //Get Query String
      const pages = ArrayOfPageUIDsFromPath(path);
      const queryString = KQLQueryStringFromArrayOfParentIDs(pages);
      //Setup Selects and Query String
      let query = {
        select: {
          site: {
            query: 'site',
            select: select.For.Site,
          },
          //page: {
          //  query: queryString,
          //  select: select.For[template] || select.For.Default
          //},
          //children: {
          //  query: queryString+'.children',
          //  select: select.For.Children
          //},
          pages: {
            query: 'site.children',
            select: select.For.Children
          }
        }
      };
      //Load
      axios.post(this.api, query).then((response) => {
        //if(!response.data.result.pages.length) throw new Error("Kirby API is not loading or does not have pages to load.");
        //if(!response.data.result.pages.length) console.error("Kirby API is not loading or does not have pages to load.");
        //if(!response.data.result.pages.length) throw new TypeError("Kirby API is not loading or does not have pages to load.");
        this.loading = this.loading-1;
        this.site = cleanUpJSON(response.data.result.site);
        //this.page = cleanUpJSON(response.data.result.page);
        this.pages = cleanUpJSON(response.data.result.pages);
       });
    },
    //Load Page
    loadPage(path = 'home', template) {
      this.loading = this.loading+1;
      //Get Query String
      const pages = ArrayOfPageUIDsFromPath(path);
      const queryString = KQLQueryStringFromArrayOfParentIDs(pages);
      //Setup Selects and Query String
      let query = {
        select: {
          page: {
            query: queryString,
            select: select.For[template] || select.For.Default
          },
          //children: {
          //  query: queryString+'.children',
          //  select: select.For.Children
          //}
        }
      }
      //Load
      axios.post(this.api, query).then((response) => {
        if(response.data.result.page!=null) {
          this.loading = this.loading-1;
          console.info('Path:', path, 'loaded', response.data);
          this.page = cleanUpJSON(response.data.result.page);
          console.log(this.page);
          //this.children = 'pagination' in response.data.result.children ? cleanUpJSON(response.data.result.children.data) : cleanUpJSON(response.data.result.children);
        } else {
          console.error('Page not found.', query);
          window.location.href = '/error'
        }
      });
    }
  },
})
