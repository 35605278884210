//YAML Helpers
import yaml from 'js-yaml';
export const yamlToObject = (string) => yaml.load(string);
export const yamlFirst = (string) => yaml.load(string)[0];

//Get image from files object by id
export const fileByUuid = function(files, uuid) {
  if(files) return cleanUpJSON(files.filter( file => (file.uuid == uuid))[0]);
}

//Simple clean up.
export const cleanUpJSON = (input) => {if(input) return JSON.parse(JSON.stringify(input))};

//URL to PATH
export const pathName = function(input) {
  let url = new URL(input);
  return url.pathname;
}

//Get alternative language
export const alternativeLanguage = function(i) {
  const linkElementForAlternate = document.head.querySelector('link[rel=alternate]');
  return linkElementForAlternate ? linkElementForAlternate.attributes.hreflang.value : false;
}

//Get image from files object by id
export const imageById = function(images, filename) {
  if(images) return cleanUpJSON(images.filter( image => (image.filename == filename))[0]);
}

//Get page object from path
export const pageFromPath = (path, pages) => {
  if (path, pages) {
    var slugs = path.trim().split('/');
    var page = false;
    var children = pages;
    while (slugs.length) {
      var id = slugs.shift();
      page = children.find(o => o.uid === id);
      if (page)
        children = typeof page.children === 'object' ? page.children : [];
    }
    if (page) {
      return page;
    } else {
      console.error('No page!', path, pages);
    }
  } else {
    console.error('No pages! Add a page element with function', path, pages);
  }
}
