import './index.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { router } from './router.js'
import App from './App.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import VueGtag from "vue-gtag"
import { pageTitle } from 'vue-page-title';

//Constants
const pinia = createPinia()
const app = createApp(App)

//Use
app.use(pinia)
app.use(router)
app.use(VueSmoothScroll)
app.use(VueGtag, {
  config: {
    id: "G-NN5CG4ZVYJ",
  },
}, router);
app.use(
  pageTitle({
    suffix: '| Public Trust',
    mixin: true,
  })
);

//Mount
app.mount('#app')
