<template>
  <main class='Home'>
    <transition-slide>
      <MainHeader v-if='reasonsToShow' :from='back' :pageToGoBackTo='pageToGoBackTo' />
    </transition-slide>
    <veil :showveil='reasonsToShow' :startcolor='startcolor' :endcolor='endcolor'/>
    <RouterViewWithTransitions id='Overlay'
                               name='overlay'
                               :key='to'
                               :origin='to'
                               @reasonsToShowTheVeil="changeToShowTheVeil"
                               @pageToGoBackTo="getPageToGoBackTo"
                               @changeColors="changeColors"/>
    <aside><Navigation /></aside>
    <Layout class='content' :content='kql.site.layout' :page='kql.site' fill='white'/>
    <router-view />
    <footer>
      <FooterLayout :content='kql.site.footer' :page='kql.site' fill='#141414'/>
      <Popup :source='kql.site' :page='kql.site'/>
    </footer>
  </main>
</template>

<script setup>
  import { ref, computed } from 'vue'
  import { useStore } from '../KQL.js'
  import { useRouter } from 'vue-router'
  const kql = useStore();
  import RouterViewWithTransitions from '../components/ViewTransitions.vue'
  import Signup from '../components/Signup.vue'
  import { TransitionSlide } from '@morev/vue-transitions';
  import Layout from '../components/Layout.vue'
  import Veil from '../components/Veil.vue'
  import FooterLayout from '../components/Footer.vue'
  import MainHeader from '../components/MainHeader.vue'
  import Navigation from '../components/Navigation.vue'
  import Popup from '../components/Popup.vue'
  const reasonsToShow = ref(0);
  const startcolor = ref(0);
  const endcolor = ref(0);
  const pageToGoBackTo = ref(false);
  const back = ref();
  const to = ref();
  const router = useRouter();
  router.beforeEach(async (to, from) => {
    console.log(to, from);
    back.value = from;
    to.value = to;
  })
  const getPageToGoBackTo = function(path){
    if(!pageToGoBackTo.value) pageToGoBackTo.value = path;
  }
  const changeColors = function(newstartcolor, newendcolor){
    startcolor.value = newstartcolor;
    endcolor.value = newendcolor;
  }
  const changeToShowTheVeil = function(reasons){
    reasonsToShow.value = reasonsToShow.value + reasons;
  }
</script>

<style scoped>
@import '../css/breakpoints.css';

  aside {
    position: fixed;
    right: 9rem;
    bottom: 0;
    top: 0;
  }

  footer {
    margin-top: 10rem;
  }

  main {
    --border: var(--second);
    color: rgb(100,70,70);
    min-height: 100vh;
    background: linear-gradient(180deg, rgba(48,25,30,1) 0%, rgba(48,25,30,1) 5%, rgba(83,71,83,1) 18%, rgba(139,118,99,1) 28%, rgba(255,176,5,1) 40%, rgba(255,176,5,1) 100%);
  }

  @media (--desktop) {
    main {
      padding: 6rem 12rem;
    }
  }

  @media (--phone) {
    main {
      padding: 3rem 4rem;
      padding-right: 9rem;
    }
  }

</style>

<style>
  @media print {
    main.Home > article.content{
      display: v-bind( reasonsToShow ? 'none' : 'block');
    }
  }

  main.Home article.content > section:nth-child(even).text {
    margin-left: 20%;
  }

  main.Home article.content > section.heading + section.text  {
    margin-left: 0%;
  }

  main.Home article.content > section:nth-child(even).logos {
    margin-left: 20%;
  }

  main.Home article.content > section:nth-child(even).intro {
    margin-left: 20%;
  }

  @media (--phone) {
    main.Home article.content > section:nth-child(even).text {
      margin-left: 0;
    }
    main.Home article.content >  section:nth-child(even).logos {
      margin-left: 0%;
    }
    main.Home article.content > section:nth-child(even).intro {
      margin-left: 0%;
    }
  }
</style>
