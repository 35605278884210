<template>
  <nav>
    <button @click.native="scrollToId(anchor)" v-for="anchor in anchors" v-html="anchor" />
  </nav>
</template>

<script setup>
  import { accessNavigation } from './NavigationStore.js'
  import { computed } from 'vue'
  const navigation = accessNavigation();
  const anchors = computed(() => navigation.anchors);
  const scrollToId = function(id) {
    document.getElementById(id).scrollIntoView();
  }
</script>

<style scoped>
@import '../css/breakpoints.css';

  nav {
    padding-left: 12rem;
    width: 100vh;
    transform: rotate(90deg);
    transform-origin: 0% 0%;
    left: 6.5rem;
    position: absolute;
  }
  nav button {
      font-family: "Maison Neue Demi";
      font-size: 1.5rem;
      font-weight: normal;
      margin-top: 0;
      color: var(--third);
      display: inline-block;
      margin-right: 1em;
      background-color: transparent;
      padding: 0;
      outline: none;
      border: none;
      position: relative;
      cursor: pointer;
  }

  nav button:after {
    position: absolute;
    bottom: -0.25em;
    left: 0;
    content: "";
    background: var(--third);
    height: 2px;
    width: 100%;
    transition: transform .4s ease;
    transform-origin: 0%;
    transform: scalex(0);
}

  nav button:hover:after {
    transform: scalex(1);
  }

  @media (--phone) {
    nav button {
        font-size: 2rem;
    }
  }
</style>
