<template>
  <transition-fade>
    <h4 v-if='!loading && from && from.params.primi'>
      <router-link v-if='!clicked && pageToGoBackTo == from.params.primi' :to='from.path' @click='click(1)'>
        ← Back
      </router-link>
      <!--<router-link v-if='clicked' :to='from.path' @click='click(0)'>
        Back →
      </router-link>-->
    </h4>
  </transition-fade>
</template>

<script setup>
import { TransitionFade } from '@morev/vue-transitions';
import { useStore } from '../KQL.js'
import { watch, ref, onUnmounted } from 'vue'
const props = defineProps(['from', 'pageToGoBackTo']);
const kql = useStore();
const loading = ref(1);
const clicked = ref(0);
var clickedTimeOut, loadingTimeOut
const click = (value) => {clickedTimeOut = setTimeout(() => { clicked.value=value }, 750)};
watch(kql, (kql) => {
  if(!kql.loading) {loadingTimeOut = setTimeout(() => { loading.value=0; }, 1000)};
  if(kql.loading) loading.value=1;  clicked.value=0;
})
onUnmounted(()=> {clearTimeout(clickedTimeOut); clearTimeout(loadingTimeOut);});
</script>
