<template>
  <section @click="toggle(); playing=!playing">
    <figure class="player">
      <Vimeo v-if='isVimeo' :class='{showorhide : block.content.show == "false", show : (block.content.show != "false" || playing)}' :url='block.content.url' ref='player' @metadata='loadMetadata'/>
      <YouTubeWrapper v-if='isYoutube' :url='block.content.url' ref='player'/>
      <figcaption>
        <Metadata>
          <template v-slot:button>
            <Play :playing='playing' />
          </template>
          <template v-slot:header>
            <div class="meter">
              <div class="info">
                <h4 class="type">Video</h4>
                <h4 v-if='embeddata && embeddata.duration' v-html='dayjs.duration(embeddata.duration*1000).format("HH:mm:ss")' />
                <h4 v-else-if='block.content.length' v-html='block.content.length' />
              </div>
              <!--<div v-if='isVimeo' class="progress" :style="'width: '+100 * played+'%'"></div>-->
              <div class="bar"></div>
            </div>
          </template>
          <template v-slot:footer>
            <h4 v-if='embeddata && embeddata.title' v-html='embeddata.title' />
            <summary class="video content" v-html='block.content.caption' />
          </template>
        </Metadata>
      </figcaption>
    </figure>
  </section>
</template>

<script setup>
  import { ref, computed } from 'vue'
  import Play from './Play.vue'
  import Metadata from './Metadata.vue'
  import Vimeo from './Vimeo.vue'
  import YouTubeWrapper from './YouTubeWrapper.vue'
  import { useStore } from '.././KQL.js'
  import dayjs from 'dayjs'
  import duration from 'dayjs/plugin/duration'
  dayjs.extend(duration);
  const kql = useStore();
  const props = defineProps(['block']);
  const player = ref(null);
  const playing = ref(false);
  const isVimeo = computed(() => props.block.content.url.includes("vimeo.com"));
  const isYoutube = computed(() => props.block.content.url.includes("youtube.com"));
  const toggle = () => { !playing.value ? player.value.play() : player.value.pause() };
  const embeddata = ref(false);
  const loadMetadata = (data) => {
    embeddata.value = data;
  };
</script>


<style scoped>
  figure {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*aspect-ratio: 16 / 9;*/
    position: relative;
    margin-top: 2rem;
    margin-bottom: 4rem;
    overflow: visible;
  }

  .showorhide {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s ease-in-out;
  }

  .showorhide.show {
    max-height: 100vh;
    overflow: hidden;
  }

  .player .info {
    display: flex;
    justify-content: space-between;
  }

  .player .meter {
    display: block;
    cursor: pointer;
    position: relative;
    border: none;
    top: 0rem;
    right: 0rem;
    width: calc(100%);
    height: 0.25rem;
    margin: auto;
    margin-bottom: 0.9rem;
    z-index: 2;
    text-align: left;
  }

  .player .bar {
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    height: 0.25rem;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 100%;
  }

  @media print {
    .player .bar {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  .player .progress {
    position: absolute;
    pointer-events: none;
    width: 0%;
    height: 0.25rem;
    left: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 1);
    display: block;
    /* @include transition(height 0.3s linear); */
  }

  .aftertitle {
    margin-top: 7.75rem !important;
  }

  .player .meter {
    height: 2.25rem;
  }

  .player .meter .info h4 {
    vertical-align: top;
    display: inline-block;
    line-height: 1em;
  }

  .player .meter .info .timer {
    float: right;
  }
</style>
