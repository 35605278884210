<template>
  <component :class='{ "hidden" : block.content.hidden==="true" }' :id='block.content.text' :is='block.content.level' v-html='block.content.text' />
</template>

<script setup>
  import { watchEffect } from 'vue'
  import { accessNavigation } from './NavigationStore.js'
  const navigation = accessNavigation();
  const props = defineProps({block: Object});
  watchEffect(() => {
   if (props.block.content.navigatable == 'true') {
     navigation.addToNavigation(props.block.content.text);
   };
  });
</script>

<style scoped>

.hidden {
  opacity: 0;
}

</style>
