import * as VueRouter from 'vue-router';

import Home from './views/Home.vue'
import About from './views/About.vue'
import Overlay from './views/Overlay.vue'

const routes = [
  { path: '/home', redirect: '/' },
  { name: 'Home',
    path: '/',
    component: Home,
    children: [
      {
        name: 'Overflow',
        path: '/:primi',
        components: {
          overlay: Overlay,
        }
      },
      {
        name: 'Error',
        path: '/*',
        components: {
          overlay: Overlay,
        }
      }

    ]
  },
]

export const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: routes,
  //routes, // short for `routes: routes`
})
