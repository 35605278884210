const For = {}

/* BASE FIELDS FOR PAGES */
const Image = {
  uuid: true,
  id: true,
  caption: 'file.caption.kirbytext',
  meta: true,
  filename: true,
  url: true,
  width: 'file.width',
  height: 'file.height',
  ratio: 'file.ratio',
  srcset: 'file.srcset',
};

/* BASE FIELDS FOR PAGES */
const File = {
  uuid: true,
  id: true,
  caption: 'file.caption.kirbytext',
  meta: true,
  filename: true,
  url: true,
};


/* GENERAL QUERY STRUCTURE FOR IMAGES */
For.Images = {
  query: 'page.images',
  select: {
    ...Image
  }
};

/* BASE FIELDS FOR PAGES */
const Base = {
  title: true,
  appendix: true,
  startcolor: true,
  endcolor: true,
  uid: true,
  uuid: true,
  url: true,
  text: 'page.text.kirbytext',
  cover: true,
  listed: 'page.isListed',
};

//GENERAL QUERY STRUCTURE FOR CHILDREN
For.Children = {
  ...Base,
};

/* QUERY STRUCTURE FOR DOCUMENTS */
For.Files = {
  query: 'page.files',
  select: {
    ...File
  }
};

For.Default = {
  ...Base,
  layout: true,
  images: For.Images,
  files: For.Files,
}

For.Home = {
  title: true,
  layout: true,
  uid: true,
  url: true,
  cover: true,
  listed: 'page.isListed',
}

For.Site = {
  url: true,
  layout: true,
  footer: true,
  blocks: true,
  ui: true,
  images: {
    query: 'site.images',
    select: {
      uuid: true,
      id: true,
      caption: true,
      filename: true,
      url: true,
      width: 'file.width',
      height: 'file.height',
      ratio: 'file.ratio',
      srcset: 'file.srcset',
    }
  }
}

export default {
  For
}
