<template>
<nav class="player">

  <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
  <!-- !Buttons -->
  <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->


  <Metadata>
    <template v-slot:button>
      <Play @click="toggleAudio(); playing=!playing" :playing='playing' />
    </template>
    <template v-slot:header>
      <div class="meter">
        <div class="info">
          <h4 class="type">Audio</h4>
          <h4 class="timer">{{dayjs.duration(currentTime*1000).format('HH:mm:ss')}} / {{dayjs.duration(audioDuration*1000).format('HH:mm:ss')}}</h4>
        </div>
        <div class="progress" :style="'width: '+100 * played+'%'"></div>
        <div class="bar"></div>
      </div>
    </template>
    <template v-slot:footer>
      <audio ref='file' preload="metadata" @loadedmetadata="getSpecs" @timeupdate="changeTimestamp" @ended='playing=!playing; currentTime=0'>
        <source type="audio/mp3" :src="fileByUuid(page.files, block.content.soundfile[0]).url" />
      </audio>
      <h4 class="audio title" v-html="block.content.title" />

      <nav class="audio appendix" v-if='page.files, block.content.soundfile[0]'>
        <h4 class="appendix content" v-html='block.content.info' />
        <h4 class="download" ><a target="_self" :href="fileByUuid(page.files, block.content.soundfile[0]).url" :download="fileByUuid(page.files, block.content.soundfile[0]).url">↓ Download</a></h4>
      </nav>
    </template>
  </Metadata>


</nav>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import Play from './Play.vue'
import Metadata from './Metadata.vue'
import { fileByUuid } from '.././helpers.js'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(duration);
dayjs.extend(relativeTime);
const props = defineProps(['block', 'page']);


const file = ref();
const playing = ref(false);
const format = 'HH:mm:ss';
const audioDuration = ref(0);
const currentTime = ref(0);
const getSpecs = () => { audioDuration.value = file.value.duration };
const changeTimestamp = () => {currentTime.value = file.value.currentTime};
const played = computed(() => currentTime.value / audioDuration.value);
const toggleAudio = () => { !playing.value ? file.value.play() : file.value.pause() };
</script>

<style scoped>
.player {
  position: relative;
  width: 100%;
}

.player .wrapper:not(.ready) .controls {
  opacity: 0;
  pointer-events: none;
}

.player .meter {
  display: block;
  cursor: pointer;
  position: relative;
  border: none;
  top: 0rem;
  right: 0rem;
  width: calc(100%);
  height: 0.25rem;
  margin: auto;
  z-index: 2;
  text-align: left;
}

.player .bar {
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  height: 0.25rem;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 100%;
}

.player .progress {
  position: absolute;
  pointer-events: none;
  width: 0%;
  height: 0.25rem;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 1);
  display: block;
  /* @include transition(height 0.3s linear); */
}

.aftertitle {
  margin-top: 7.75rem !important;
}

.player .meter {
  height: 2.25rem;
}

.player .meter .info h4 {
  vertical-align: top;
  display: inline-block;
  line-height: 1em;
}

.player .meter .info .timer {
  float: right;
}


.player .progress {
  background-color: white;
}

.title {
  padding: 0.75rem 0 0 0;
}

.audio.appendix {
  width: calc(100%);
  text-align: left;
}

.audio.appendix h4 {
  display: inline-block;
  vertical-align: top;
  line-height: 1em;
}

.download {
  float: right;
  text-decoration: none !important;
}

p {
  margin: 0;
}
</style>
