import { defineStore } from 'pinia';
export const accessNavigation = defineStore('navigation', {
  state: () => {
    return {anchors: []}
  },
  actions: {
    addToNavigation(title) {
      (this.anchors.indexOf(title)) === -1 ? this.anchors.push(title) : ''; ///console.log(title +' is already in navigation.')
    }
  }
});
