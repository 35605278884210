<script setup>
  import YouTube from 'vue3-youtube'
  import { ref } from 'vue'

  const props = defineProps({
    url: {
        type: String,
        required: true,
    }
  });
  const youtubeOptions = {
    controls: 0
  };
  const youtubePlayer = ref(null);
  const play = () => youtubePlayer.value.play();
  const pause = () => youtubePlayer.value.pause();
  defineExpose({play, pause});
</script>

<template>
  <YouTube :src='props.url' :vars='youtubeOptions' ref='youtubePlayer' width='' height='' />
</template>

<style scoped>
  figure {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 16 / 9;
    overflow: hidden;
  }
</style>

<style>
  .video-wrapper iframe {
    width: 100% !important;
    aspect-ratio: 16 / 9 !important;
  }
</style>
