<template>
  <transition name='basic'>
    <footer id='Subscribe-Popup' v-show='show && !closed && dismissed!="true"'>
      <svg class='close' viewBox='0 0 44 44' @click='dismiss()'>
        <path class='circle' d="M21.97 43.93a22 22 0 1 0-22-22 22 22 0 0 0 22 22"/>
        <path class='cross' d="m32.21 30.5-8.12-8.11 8.11-8.12-2.12-2.12-8.12 8.11-8.11-8.11-2.12 2.12 8.11 8.12-8.11 8.11 2.12 2.12 8.11-8.11 8.13 8.12 2.12-2.13z"/>
      </svg>
      <Blocks v-if='source.blocks' :content='JSON.parse(source.blocks)' />
    </footer>
  </transition>
</template>

<script setup>
  import { ref, computed } from 'vue'
  import { useCookies } from "vue3-cookies";
  import Blocks from './Blocks.vue'
  //import { imageByUuid } from '.././helpers.js'
  const { cookies } = useCookies();
  const props = defineProps(['source']);
  const show = ref(true);
  const closed = ref(false);
  const dismissed = computed(() => { return cookies.get('dismissed'); });
  const codeblocks = computed(() => {
    if(props.source){
      const columns = JSON.parse(props.source)[0].columns;
      let blocks = [];
      columns.forEach((column) => {
        blocks = blocks.concat(column.blocks);
      });
      let returnArray = blocks.filter(function(block){
        return block.type == 'code';
      });
      return returnArray;
    }
  });
  const dismiss = function() {
    console.log(cookies.get('dismissed'));
    closed.value=true;
    var dismissed = cookies.set('dismissed', 'true');
    return dismissed
  };
</script>

<style scoped>

  @media print {
    footer {
      display: none;
    }
  }

  footer {
    font-family: "Maison Neue Demi";
    position: fixed;
    width: 27rem;
    bottom: 3rem;
    right: 2rem;
    padding: 2rem 3rem;
    padding-bottom: 3rem;
    background-color: rgba(255,176,5,0.9);
    max-width: var(--main-max-width);
    box-sizing: border-box;
    border-radius: 2rem;
    text-align: left;
    z-index: 999;
    box-shadow: 0rem 0.25rem 0.5rem var(--blueopacity);
    overflow: hidden;
    border: 1px solid var(--border);
  }

  .cross {
    fill: var(--third);
  }

  .circle {
    fill: var(--first);
  }

  svg.close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease-out;
  }

  svg.close:hover {
    transform: scale(1.05);
  }
</style>
