<template>
  <div>
    <nav>
      <slot name="button"></slot>
    </nav>
    <aside>
      <header>
        <slot name="header"></slot>
      </header>
      <footer>
        <slot name="footer"></slot>
      </footer>
    </aside>
  </div>
</template>

<style scoped>
  div {
    display: flex;
    margin-top: 4rem;
  }

  nav {
    padding-right: 2rem;
  }

  aside {
    width: 100%;
  }

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  footer {
    font-family: "Maison Neue Demi";
    font-size: 1.5rem;
    font-weight: normal;
  }
  footer:deep(a) {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: rgba(255,255,255,0.5);
  }

  footer:deep(a):hover {
    text-decoration-line: none;
  }

  footer:deep(em) {
    font-weight: normal;
    display: inline-block;
    transform: skew(-12deg);
  }

  footer:deep(strong) {
    text-transform: uppercase;
  }

</style>
